<template>
  <v-app>
    <top-bar />

    <nav-drawer 
      @reset="onReset"
    />

    <page-view />

    <v-overlay
      :value="updating"
      opacity=".8"
      class="text-center"
    >
      <v-progress-circular 
        indeterminate 
        size="64" 
        color="primary"
        z-index="10"
      />
      <v-subheader>
        Verificando updates...
      </v-subheader>
    </v-overlay>

    <toast />
  </v-app>
</template>

<script>
  // Utilities
  import { sync } from 'vuex-pathify'

  export default {
    name: 'LayoutAuditor',

    props: {
      updating: {
        type: Boolean,
        default: false,
      }
    },

    components: {
      TopBar: () => import('./AppBar'),
      NavDrawer: () => import('./Drawer'),
      PageView: () => import('./View'),
      Toast: () => import('@/components/Toast'),
    },

    computed: {
      drawer: sync('app/views@drawer'),
      toast: sync('app/toast'),
    },

    methods: {
      onReset () {
        this.$emit('reset');
      }
    },

    beforeDestroy () {
      this.drawer.toggle = false;
    },
  }
</script>
